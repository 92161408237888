const sellerRouter = [
  {
    name: 'Vendedores',
    path: 'vendedores',
    meta:{
      auth: true,
      p:'ruta-vendedores.consulta'
    },
    component: () => import(
      /* webpackChunkName: "views-[request]" */
      '@/views/Vendedores/index'
    ),
  },
  {
    name: 'Ubicacion',
    path: 'ubicacion',
    meta:{
      auth: true,
      p:'ruta-vendedores.consulta'
    },
    component: () => import(
      /* webpackChunkName: "views-[request]" */
      '@/views/Vendedores/Ubicacion'
    ),
  },
  {
    name: 'Vendedor',
    path: 'detalles/:cod',
    meta:{
      auth: true,
      p:'ruta-vendedores.consulta'
    },
    component: () => import(
      /* webpackChunkName: "views-[request]" */
      '@/views/Vendedores/Details'
    ),
  },
]

export default sellerRouter
