// Utilities
import { make } from 'vuex-pathify'
import api from '../../util/request'
import * as cookies from '../../util/auth_user'
import { resetRouter } from '@/router'

// Globals
import { IN_BROWSER } from '@/util/globals'

const state = {
  info:{},
  token: cookies.GET_TOKEN(),
}

const mutations = {
  ...make.mutations(state),
  RESET_INFO(state){
    state.info  = {};
    state.token = null;
  }
}

const actions = {
  // fetch: ({ commit }) => {
  //   const local = localStorage.getItem('vuetify@user') || '{}'
  //   const user = JSON.parse(local)

  //   for (const key in user) {
  //     commit(key, user[key])
  //   }

  //   if (user.dark === undefined) {
  //     commit('dark', window.matchMedia('(prefers-color-scheme: dark)'))
  //   }
  // },
  // update: ({ state }) => {
  //   if (!IN_BROWSER) return

  //   localStorage.setItem('vuetify@user', JSON.stringify(state))
  // },
  async getInfo({commit}) {
    const  me = await api.get('auth/me');
    commit('info', me.data);
    return me.data;
  },
  async login({ commit }, credentials) {
    const  response = await api.post('auth/login', credentials);

    cookies.SET_TOKEN(response.data.token);
    commit('token', response.data.token);
    // commit('info', response.data.user);
  },
  async logout({commit, dispatch}) {
    dispatch('app/setOverlay', true, { root: true});
    await api.get('auth/logout');
    cookies.REMOVE_USER();
    resetRouter();
    commit('RESET_INFO');
    dispatch('app/setMenuApp', [], { root: true});
    dispatch('permissions/SET_ROUTES', [], { root: true});
  },

  closeSession({commit}) {      
    cookies.REMOVE_USER();
    commit('RESET_INFO');
    dispatch('app/setMenuApp', [], { root: true});
    dispatch('permissions/SET_ROUTES', [], { root: true});
    resetRouter();
  },

  deleteInfo({commit}){
    commit('info', {});
    resetRouter();
  }
}

const getters = {
  ...make.getters(state),
  authenticated (state) {
    return state.token !== null
  },
  infoBasic (state) {
    
    const { name: username, email, sucursal } = state.info

    return state.info.id 
      ? { username, email, sucursal } 
      : ''
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
