<template>
  <v-app :style="styleFullscreen">
    <v-fade-transition mode="out-in">
      <router-view />
    </v-fade-transition>
    <default-overlay />
  </v-app>
</template>

<script>
  // Styles
  import '@/styles/overrides.sass'
  import { get } from 'vuex-pathify'

  export default {
    name: 'App',
    metaInfo: {
      title: 'App',
      titleTemplate: '%s | Isola',
      htmlAttrs: { lang: 'eS' },
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      ],
    },
    components:{
      DefaultOverlay: () => import(
        /* webpackChunkName: "default-overlay" */
        './layouts/default/AppOverlay'
      ),
    },
    computed: {
      ...get('app', [
        'mini',
      ]),
      styleFullscreen(){
        return {
          "--leftFullscreen": this.mini ? '80px' : '250px',
        }
      }
    },
  }
</script>
<style>
.v-dialog--fullscreen {
  left: var(--leftFullscreen) !important
}

</style>
<!-- .v-dialog__content, .v-dialog {
  width: calc(100% - var(--leftFullscreen)) !important;
} -->
