const AdmintRouter = [
  {
    name: 'Cargar Horarios',
    path: 'cargar-horarios',
    meta:{
      auth: true,
      p:'administracion.carga-horario'
    },
    component: () => import(
      /* webpackChunkName: "views-[request]" */
      '@/views/Administracion/CargaHorarios/index'
    ),
  },
  {
    name: 'Cargar Multiarchivos',
    path: 'cargar-multiarchivos',
    meta:{
      auth: true,
      p:'administracion.carga-multiarchivo'
    },
    component: () => import(
      /* webpackChunkName: "views-[request]" */
      '@/views/Administracion/CargaMultiarchivos/index'
    ),
  },
  {
    name: 'Bancos',
    path: 'bancos',
    meta:{
      auth: true,
      p:'administracion.bancos.consulta'
    },
    component: () => import(
      /* webpackChunkName: "views-[request]" */
      '@/views/Administracion/Bancos/index'
    ),
  },
  {
    name: 'Motivos no cobros y no ventas',
    path: 'motivos',
    meta:{
      auth: true,
      p:'administracion.motivos.consulta'
    },
    component: () => import(
      /* webpackChunkName: "views-[request]" */
      '@/views/Administracion/Motivos/index'
    ),
  },
  {
    name: 'Historial (Logs)',
    path: 'logs',
    meta:{
      auth: true,
      p: 'administracion.historial',
    },
    component: () => import(
      /* webpackChunkName: "views-[request]" */
      '@/views/Administracion/Logs'
    ),
  },
  {
    name: 'Tareas Automáticas',
    path: 'tareas-automaticas',
    meta:{
      auth: true,
      p: 'administracion.tareas',
    },
    component: () => import(
      /* webpackChunkName: "views-[request]" */
      '@/views/Administracion/Tasks'
    ),
  },
]

export default AdmintRouter
