export function leadingSlash (str) {
  return str.startsWith('/') ? str : '/' + str
}

export function trailingSlash (str) {
  return str.endsWith('/') ? str : str + '/'
}

export const wait = timeout => {
  return new Promise(resolve => setTimeout(resolve, timeout))
}

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export const totalClientesNoPlanificado = (item) => {
  return item.clientes.length > 0
    ? item.clientes.reduce((acc, item) => {
        return item.visita && item.visita.cod_visita_tipo > 1 ? acc + 1 : acc
      }, 0)
    : null
}

export const total = ({items, key}) => {
  return items.length > 0
    ? items.reduce((acc, item) => {
        return acc + parseFloat(item[key])
      }, 0)
    : null
}
