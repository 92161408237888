<template>
  <v-container
    id="mensajeria"
    fluid
    tag="section"
    class="pa-0"
    style="padding-right: 80px !important; min-height: 100vh"
  >
    <main-header />
    <v-navigation-drawer
      absolute
      width="80"
      right
      color="blue-grey lighten-1"
      dark
    >
      <v-list dense class="pt-0">
      <!-- <v-divider></v-divider>
      <v-list-item class="py-3" active-class="icono">
        <v-list-item-content>
          <v-list-item-title class="d-flex flex-column justify-center align-center">
            <v-icon>mdi-plus</v-icon>
            <span class="text-subtitle">Nuevo</span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
      <v-divider></v-divider>
      <v-list-item class="py-3" active-class="icono" :to="{name: 'Grupos'}">
        <v-list-item-content>
          <v-list-item-title class="d-flex flex-column justify-center align-center">
            <v-icon>mdi-account-group</v-icon>
            <span class="text-subtitle">Grupos</span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item class="px-0 py-3" active-class="icono" :to="{name : 'Plantillas'}">
        <v-list-item-content>
          <v-list-item-title class="d-flex flex-column justify-center align-center">
            <v-icon>mdi-text-box-check</v-icon>
            <span class="text-subtitle">Plantillas</span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>

      </v-list>
    </v-navigation-drawer>

    <router-view :key="key" />
  </v-container>
</template>
<script>
export default {
  name: 'LayoutMesseger',
  created() {},
  computed: {
    key() {
      return this.$route.path
    },
  },
}

</script>
