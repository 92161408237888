// Pathify
import { make } from 'vuex-pathify'

// Data
const state = {
  drawer: null,
  drawerImage: true,
  mini: false,
  gutters: true,
  overlay: false,
  menuApp: [],
  items: [
    {
      title: 'Principal',
      icon: 'mdi-view-dashboard',
      to: '/',
    },
    {
      title: 'Inventario',
      meta: {
        p: 'inventario.consulta',
      },
      icon: 'mdi-format-list-text',
      to: '/inventario/',
    },
    {
      title: 'Clientes',
      meta: {
        p: 'clientes',
      },
      icon: 'mdi-briefcase-account',
      children:[
        {
          title: 'Listado',
          meta: {
            p: 'clientes.consulta',
          },
          icon: 'mdi-format-list-text',
          to: '/clientes/',
          divider: true,
        },
        {
          title: 'Ubicación',
          meta: {
            p: 'clientes.mapa',
          },
          icon: 'mdi-map-marker-radius',
          to: '/ubicacion-clientes',
          divider: true,
        },
        {
          title: 'Potenciales',
          meta: {
            p: 'clientes.consulta',
          },
          icon: 'mdi-account-arrow-right',
          to: '/clientes-potenciales/',
          divider: true,
        },
      ]
    },
    {
      title: 'Rutas Vendedores',
      meta: {
        p: 'ruta-vendedores',
      },
      icon: 'mdi-account-supervisor-circle',
      children: [
        {
          title: 'Vendedores',
          meta: {
            p: 'ruta-vendedores.consulta',
          },
          icon: 'mdi-format-list-text',
          to: '/rutas-vendedores/vendedores',
        },
        {
          title: 'Ubicación',
          meta: {
            p: 'ruta-vendedores.consulta',
          },
          icon: 'mdi-map-marker-radius',
          to: '/rutas-vendedores/ubicacion',
        },
      ],
    },
    {
      title: 'Cobros',
      meta: {
        p: 'cobros.consulta',
      },
      icon: 'mdi-text-box-check',
      to: '/cobros',
    },
    {
      title: 'Reportes',
      meta: {
        p: 'reportes',
      },
      icon: 'mdi-chart-bar',
      children:[
        {
          title: 'Resumen',
          meta: {
            p: 'reportes.resumen.consulta',
          },
          icon: 'mdi-text-box-check-outline',
          to: '/reporte/resumen',
          divider: true,
        },
        {
          title: 'Efectividad',
          meta: {
            p: 'reportes.efectividad.consulta',
          },
          icon: 'mdi-chart-line',
          to: '/reporte/efectividad',
          divider: true,
        },
        {
          title: 'Resumen Excel',
          meta: {
            p: 'reportes.resumen_excel.consulta',
          },
          icon: 'mdi-file-chart-outline',
          to: '/reporte/resumen-excel',
          divider: true,
        },
        // {
        //   title: 'Resumen General',
        //   meta: {
        //     p: 'reportes.resumen_general.consulta',
        //   },
        //   icon: 'mdi-text-box-multiple-outline',
        //   to: '/reporte/resumen-general',
        //   divider: true,
        // },
        {
          title: 'Comparativo de Visitas',
          meta: {
            p: 'reportes.visitas_totales.consulta',
          },
          icon: 'mdi-database-marker',
          to: '/reporte/comparativo-visitas',
          divider: true,
        },
        {
          title: 'Cuentas Por Cobrar',
          meta: {
            p: 'reportes.cobranzas.consulta',
          },
          icon: 'mdi-file-alert-outline',
          to: '/reporte/cuentas-por-cobrar',
          divider: true,
        },
        {
          title: 'Geolocalizados',
          meta: {
            p: 'reportes.geolocalizacion.consulta',
          },
          icon: 'mdi-home-map-marker',
          to: '/reporte/clientes-geolocalizados',
          divider: true,
        },
      ]
    },
    {
      title: 'Mensajería',
      meta: {
        p: 'cobros.consulta',
      },
      icon: 'mdi-message-text-outline',
      to: '/mensajeria',
    },
    {
      title: 'Administración',
      meta: {
        p: 'administracion',
      },
      icon: 'mdi-cog-transfer',
      children:[
        {
          title: 'Tareas',
          meta: {
            p: 'administracion.tareas',
          },
          icon: 'mdi-monitor-dashboard',
          to: '/administracion/tareas-automaticas',
          divider: true,
        },
        {
          title: 'Cargar Horarios',
          meta: {
            p: 'administracion.carga-horario',
          },
          icon: 'mdi-file-upload',
          to: '/administracion/cargar-horarios',
          divider: true,
        },
        {
          title: 'Cargar Multiarchivos',
          meta: {
            p: 'administracion.carga-multiarchivo',
          },
          icon: 'mdi-file-upload',
          to: '/administracion/cargar-multiarchivos',
          divider: true,
        },
        {
          title: 'Bancos',
          meta: {
            p: 'administracion.bancos.consulta',
          },
          icon: 'mdi-bank',
          to: '/administracion/bancos',
          divider: true,
        },
        {
          title: 'Motivos',
          meta: {
            p: 'administracion.motivos.consulta',
          },
          icon: 'mdi-tag-multiple-outline',
          to: '/administracion/motivos',
          divider: true,
        },
        {
          title: 'Historial',
          meta: {
            p: 'administracion.historial',
          },
          icon: 'mdi-history',
          to: '/administracion/logs',
          divider: true,
        },
      ]
    },
  ],
  statusClient:{
    rojo: '#FF353E',
    amarillo: '#FB8C00',
    verde: '#009B35'
  }
}

const mutations = make.mutations(state)

const actions = {
  ...make.actions(state),
  init: async ({ dispatch }) => {
    //
  },
}

const getters = {
  overlay(state){
    return state.overlay;
  },
  items(state){
    return state.items;
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
