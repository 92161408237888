// Vuetify Documentation https://vuetifyjs.com

import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import ripple from 'vuetify/lib/directives/ripple'
import '@mdi/font/css/materialdesignicons.min.css'
import es from 'vuetify/es5/locale/es'

Vue.use(Vuetify, { directives: { ripple } })

const theme = {
  // primary: '#164360',
  primary: '#003B49',
  // secondary: '#63B041',
  secondary: '#43B02A',
  accent: '#24404E',
  info: '#00CAE3',
  success: '#4CAF50',
  warning: '#FB8C00',
  error: '#FF5252',
  label: '#B9C2D0',
  icono: '#2CC09C',
}

export default new Vuetify({
  lang: {
    locales: { es },
    current: 'es',
  },
  breakpoint: { mobileBreakpoint: 960 },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },  
  iconfont: 'mdi',
})
