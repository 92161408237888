// Imports
import Vue from 'vue'
import Router from 'vue-router'
import LayoutChildren from '@/layouts/default/DefaultChildren'
import LayoutMesseger from '@/views/Mensajeria/Layout'
import reportesRouter from './modules/Reportes'
import AdmintRouter from './modules/Admin'
import ConfgtRouter from './modules/Configuracion'
import MensajeriaRouter from './modules/Mensajeria'
import sellerRouter from './modules/Vendedores'

Vue.use(Router)

// VARIABLE QUE CONTIENE TODAS LAS RUTAS DEL USUARIO LOGUEADO
export const dynamicRoutes = [
  {
    name: 'Principal',
    path: '',
    meta:{
      auth: true,
    },
    component: () => import(
      /* webpackChunkName: "views-[request]" */
      '@/views/Dashboard'
    ),
  },
  {
    name: 'Inventario',
    path: '/inventario',
    meta:{
      auth: true,
      p:'inventario.consulta'
    },
    component: () => import(
      /* webpackChunkName: "views-[request]" */
      '@/views/Inventario/index'
    ),
  },
  {
    name: 'Articulo',
    path: '/inventario/articulo/:cod/:moneda',
    meta:{
      auth: true,
      p: 'inventario.consulta'
    },
    component: () => import(
      /* webpackChunkName: "views-[request]" */
      '@/views/Inventario/Details'
    ),
  },
  {
    name: 'Administración',
    path: '/administracion',
    meta:{
      auth: true,
      p:'administracion'
    },
    component: LayoutChildren,
    children: AdmintRouter
  },
  {
    name: 'Configuracion',
    path: '/configuracion',
    meta:{
      auth: true,
    },
    component: LayoutChildren,
    children: ConfgtRouter,
  },
  {
    name: 'SMS',
    path: '/mensajeria',
    redirect: {name: 'Mensajeria'},
    meta:{
      auth: true,
    },
    component: LayoutMesseger,
    children: MensajeriaRouter,
  },
  {
    name: 'Rutas Vendedores',
    path: '/rutas-vendedores',
    meta:{
      auth: true,
    },
    component: LayoutChildren,
    children: sellerRouter,
  },
  {
    name: 'Clientes',
    path: '/clientes',
    meta:{
      auth: true,
      p:'clientes.consulta'
    },
    component: () => import(
       /* webpackChunkName: "views-[request]" */
      '@/views/Clientes/index'
    ),
  },
  {
    name: "Agregar Vendedores a Clientes",
    path: "/AddSellerToClient/:co_cli/:portafolio",
    meta: {
      auth: true,
      p: "clientes.consulta"
    },
    component: () =>
      import(
        /* webpackChunkName: "views-[request]" */
        "@/views/Clientes/Components/AddSellerToClient"
      )
  },
  {
    name: "Clientes Potenciales",
    path: "/clientes-potenciales",
    meta: {
      auth: true,
      p:'clientes.consulta'
    },
    component: () => import(
       /* webpackChunkName: "views-[request]" */
      '@/views/Clientes/Potenciales/index'
    ),
  },
  {
    name: 'Ubicacion de Clientes',
    path: '/ubicacion-clientes',
    meta:{
      auth: true,
      p:'clientes.mapa'
    },
    component: () => import(
       /* webpackChunkName: "views-[request]" */
      '@/views/Clientes/UbicacionClientes'
    ),
  },
  {
    name: 'Cliente',
    path: '/clientes/:cod/:tab?',
    meta:{
      auth: true,
      p:'clientes.consulta'
    },
    component: () => import(
      /* webpackChunkName: "views-[request]" */
      '@/views/Clientes/Details'
    ),
  },
  {
    name: 'ClientePotencial',
    path: '/clientes-potenciales/:cod/:tab?',
    meta:{
      auth: true,
      p:'clientes.consulta'
    },
    component: () => import(
      /* webpackChunkName: "views-[request]" */
      '@/views/Clientes/Potenciales/Details'
    ),
  },
  {
    name: 'Cobros',
    path: '/cobros',
    meta:{
      auth: true,
      p:'cobros.consulta'
    },
    component: () => import(
      /* webpackChunkName: "views-[request]" */
      '@/views/Pagos/index'
    ),
  },
  {
    name: 'Seguridad',
    path: '/seguridad/gestion-de-usuario',
    meta:{
      auth: true,
    },
    component: () => import(
      /* webpackChunkName: "views-[request]" */
      '@/views/Auth/ResetPassword'
    ),
  },
  {
    name: 'Reporte',
    path: '/reporte',
    meta:{
      auth: true,
      p:'reportes',
    },
    component: LayoutChildren,
    children: reportesRouter,
  },
  {
    name: 'Error-permission',
    path: '/403',
    meta:{
      auth: true,
    },
    component: () => import(
      /* webpackChunkName: "views-[request]" */
      '@/views/AccessDenied.vue'
    ),
  },
]

export const CoreRoute = {
  path: '',
  component: () => import(
    /* webpackChunkName: "layout-[request]" */
    '@/layouts/default/Index'
  ),
  children: [],
}

const createRouter = () => new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    {
      name: 'Login',
      path: '/auth/login',
      meta:{
        auth: false,
      },
      component: () => import(
        /* webpackChunkName: "views-[request]" */
        '@/views/Auth/Login'
      ),
    },
    {
      name: 'Error',
      path: '/404',
      component: () => import(
        /* webpackChunkName: "views-[request]" */
        '@/views/Error.vue'
      ),
    },
  ],
})

const router = createRouter();

export default router

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher
}
