const ConfgtRouter = [
  {
    name: 'Usuarios',
    path: 'usuarios',
    meta:{
      auth: true,
      p: 'configuracion.usuarios.consulta',
    },
    component: () => import(
      /* webpackChunkName: "views-[request]" */
      '@/views/Administracion/index'
    ),
  },
  {
    name: 'Permisos de Usuarios',
    path: 'permisos',
    meta:{
      auth: true,
      p: 'configuracion.permisos.consulta',
    },
    component: () => import(
      /* webpackChunkName: "views-[request]" */
      '@/views/Administracion/Permisos/index'
    ),
  },
  {
    name: 'Roles de Usuarios',
    path: 'roles',
    meta:{
      auth: true,
      p: 'configuracion.roles.consulta',
    },
    component: () => import(
      /* webpackChunkName: "views-[request]" */
      '@/views/Administracion/Roles/index'
    ),
  },
  {
    name: 'GestionRol',
    path: 'roles/:action/:id?',
    meta:{
      auth: true,
    },
    component: () => import(
      /* webpackChunkName: "views-[request]" */
      '@/views/Administracion/Roles/GestionRol'
    ),
    beforeEnter: (to, from, next) => {
      const options = ['crear', 'actualizar']
      const hasAction = options.includes(to.params.action)

      if(hasAction)
        next()
      else
        next({ name: 'Error' })
    },
  },
  {
    name: 'Parámetros del Sistema',
    path: 'parametros-sistema',
    meta:{
      auth: true,
      p: 'configuracion.roles.consulta',
    },
    component: () => import(
      /* webpackChunkName: "views-[request]" */
      '@/views/Administracion/Parametros/index'
    ),
  },
]

export default ConfgtRouter
