const MensajeriaRouter = [
  {
    name: 'Mensajeria',
    path: 'inicio',
    meta:{
      auth: true,
      p:'mensajeria.consulta'
    },
    component: () => import(
      /* webpackChunkName: "views-[request]" */
      '@/views/Mensajeria/index'
    ),
  },
  {
    name: 'Grupos',
    path: 'grupos',
    meta:{
      auth: true,
      // p:'mensajeria.consulta'
    },
    component: () => import(
      /* webpackChunkName: "views-[request]" */
      '@/views/Mensajeria/Groups'
    ),
  },
  {
    name: 'Plantillas',
    path: 'plantillas',
    meta:{
      auth: true,
      // p:'mensajeria.consulta'
    },
    component: () => import(
      /* webpackChunkName: "views-[request]" */
      '@/views/Mensajeria/Plantilla'
    ),
  },
]

export default MensajeriaRouter
