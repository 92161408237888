const ReporstRouter = [
  {
    name: 'Resumen',
    path: 'resumen',
    meta:{
      auth: true,
      p: 'reportes.resumen.consulta',
    },
    component: () => import(
      /* webpackChunkName: "views-[request]" */
      '@/views/Reportes/Report_resumen'
    ),
  },
  {
    name: 'Efectividad',
    path: 'efectividad',
    meta:{
      auth: true,
      p: 'reportes.efectividad.consulta'
    },
    component: () => import(
      /* webpackChunkName: "views-[request]" */
      '@/views/Reportes/Report_efectividad'
    ),
  },
  {
    name: 'Resumen Excel',
    path: 'resumen-excel',
    meta:{
      auth: true,
      p: 'reportes.resumen_excel.consulta',
    },
    component: () => import(
      /* webpackChunkName: "views-[request]" */
      '@/views/Reportes/Reporte_macro'
    ),
  },
  // {
  //   name: 'Resumen General',
  //   path: 'resumen-general',
  //   meta:{
  //     auth: true,
  //     p: 'reportes.resumen_general.consulta',
  //   },
  //   component: () => import(
  //     /* webpackChunkName: "views-[request]" */
  //     '@/views/Reportes/Reporte_general'
  //   ),
  // },
  {
    name: 'Cuentas por Cobrar',
    path: 'cuentas-por-cobrar',
    meta:{
      auth: true,
      p: 'reportes.cobranzas.consulta'
    },
    component: () => import(
      /* webpackChunkName: "views-[request]" */
      '@/views/Reportes/ReporteCobranzas'
    ),
  },
  {
    name: 'Geolocalizados',
    path: 'clientes-geolocalizados',
    meta:{
      auth: true,
      p: 'reportes.geolocalizacion.consulta'
    },
    component: () => import(
      /* webpackChunkName: "views-[request]" */
      '@/views/Reportes/ClientesGeolocalizados'
    ),
  },
  {
    name: 'Comparativo de Visitas',
    path: 'comparativo-visitas',
    meta:{
      auth: true,
      p: 'reportes.visitas_totales.consulta'
    },
    component: () => import(
      /* webpackChunkName: "views-[request]" */
      '@/views/Reportes/VisitasTotales/index'
    ),
  },
]

export default ReporstRouter
